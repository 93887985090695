import React from 'react';
import { Flex, Image, Text, Box} from 'rebass/styled-components';
import Fade from 'react-reveal/Fade';
import Banner from '../components/Banner';
import Layout from '../components/Layout';
import ButtonNav from '../components/ButtonNav';
import Section from '../components/Section';
import CalendarImage from '../../media/artificial-intelligence/calendar.png';
import SummaryImage from '../../media/artificial-intelligence/summary.png';
import DataVisualisationImage from '../../media/artificial-intelligence/data-visualisation.png';
import PageBanner from '../../media/artificial-intelligence/artificial-intelligence-banner.jpg';

const ArtificialIntelligence = () => { 

  return (
    <Layout>
      <Banner src={PageBanner} title="Artificial Intelligence" condensed />
      <Section.Container id="about">
        <Fade>
          <Section.HeaderLeft name="THE CHALLENGE" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[2,4]}>
            <Box width={1} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                Retail promotional activity is critical for FMCGs to maintain and grow market share, profitability and product demand. 
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                I led the design for a product that helps major FMCG companies plan their promotional calendars using Artificial Intelligence. By modeling several demand signals such as product catalogue promotions, product placement in stores, seasonality, plus competitor and cross-retailer price elasticities, the product generates promotional pricing recommendations with a high degree of accuracy.
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                I came onboard in the middle of the first work order with a major multinational soft drink manufacturer. I worked closely with both the client and the internal Product team made up of data scientists and developers to gather project requirements and define our technical capabilities. Some unique challenges for design were the product is the first of it's kind without any real equal in the market. Secondly, the products users are 'super users' with their own technical terminology and processes which needed to be accomodated within the product.
              </Text>
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeft name="METHODS" />
          <Flex justifyContent="center" flexWrap='wrap' mb={4}>
            <Box width={1} px={4} mb={[null,4]}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                As a UX/UI Designer in AI I work with the product team to bridge the gap between user needs and the technological capability. I focused on providing clarity and vision for the data scientists and developers on how those capabilities could be utilised by the end user. I looked for ways to simplify complex information, enabling end users to leverage the power of big data. I would test my designs internally and with clients and iterate based on feedback.
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                I ran discovery workshops and meetings with the product team to gather capabilities and requirements. I would then work with the design team to interpret capabilities into visual language and brainstorm potential workflows and UI components.
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                Following this initial process wireframes were created and showcased to the team for feedback and then to clients. We would iterate on designs until a solution was agreed upon by all stakeholders.
              </Text>
            </Box>
          
          </Flex>
        </Fade>
   

        <Fade>
          <Section.HeaderLeft name="DELIVERABLES" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[null,4]}>
            <Box width={1} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                I led the design effort, working collaboratively with the product and design team to produce new features and capabilities such as:
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                <ul>
                  <li>Ability for users to plan and edit promotions and view predictive KPIs.</li>
                  <li>Monitor and edit competitor promotions and view predicted KPIs and subsequent cannibalisation. </li>
                  <li>Monitor and keep track of the use of different promotion mechanics.</li>
                  <li>The ability to Optimise a calendar - this is a new workflow for the product and works by providing  an optimised, system generated new promotional calendar, abiding by all constraints entred by the user, and maximising the chosen KPI metric. This workflow affected all screens in the application.</li>
                  <li>Redesign and refresh of the application. </li>
                </ul>
              </Text>
            </Box>
          </Flex>

        </Fade>
          
        <Fade>
          <Section.HeaderLeftSmall name="CALENDAR PAGE" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[null,5]}>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Image alt="Calendar" src={CalendarImage} width={1} />
            </Box>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The calendar page displays all planned promotions and forecasted values across a range of KPIs such as Net Sales Revenue, Volume and Retail Margin. It allows the user to create multiple versions until they decide on one they're most happy with and can save and export. 
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                Each cell has 5 pieces of information: display & catalogue feature, promotion detail, KPI value and mechanic type.
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The KPI Selector allows the user to toggle between which KPI is displayed on the Calendar.
              </Text>
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeftSmall name="SUMMARY PAGE" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[null,5]}>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Image alt="Summary page" src={SummaryImage} width={1} />
            </Box>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The summary page enables KPIs comparison between scenarios in a table format.
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The user is also able to view the volume and sales of their competitor.
              </Text>
            </Box>
          </Flex>
        </Fade>

        <Fade>
          <Section.HeaderLeftSmall name="DATA VISUALISATION" />
          <Flex justifyContent="center" flexWrap='wrap' mb={[null,5]}>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Image alt="Data visualisation" src={DataVisualisationImage} width={1} />
            </Box>
            <Box width={[1,1/2]} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The data visualisation screen enables comparison of different scenarios KPI performance in an way that is easy to quickly interpret. 
              </Text>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                This enables companies to game strategies to boost certain KPIs within a set period.
              </Text>
            </Box>
          </Flex>
        </Fade>
        <Fade>
          <Section.HeaderLeft name="OUTCOMES" />
          <Flex justifyContent="center" flexWrap='wrap'>
            <Box width={1} px={4} pb={2} mb={4}>
              <Text 
                py="2"
                as="p" 
                sx={{
                  color: "headingSecondary"
                }}
                fontSize={[2,3]}
              >
                The product has acquired new contracts with several large FMCG retailers in Australia and America and both the company and its customers believe the products are unprecedented in the industry. 
              </Text>
            </Box>
            <ButtonNav 
              link="/transport"
              text="Next Project"
            /> 
          </Flex>
        </Fade>
      </Section.Container>
    </Layout>
    )
  }

export default ArtificialIntelligence;


